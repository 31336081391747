import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/international-public-finance/*" />
    <RAC path="/zh-cn/international-public-finance/*" />
    <RAC path="/zh-tw/international-public-finance/*" />
    <RAC path="/pl/international-public-finance/*" />
    <RAC path="/ru/international-public-finance/*" />
    <RAC path="/it/international-public-finance/*" />
    <RAC path="/de/international-public-finance/*" />
    <RAC path="/es/international-public-finance/*" />
    <RAC path="/fr/international-public-finance/*" />
    <RAC path="/ar/international-public-finance/*" />
    <RAC path="/id/international-public-finance/*" />
    <RAC path="/pt/international-public-finance/*" />
    <RAC path="/ko/international-public-finance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
